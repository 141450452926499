import { Component, Vue } from "vue-property-decorator";
import { IsDefined, IsNotEmpty, MaxLength, validate } from "class-validator";

import { SideNav } from "@/layout";
import { Lightbox } from "@/components";
import { ErrorCode, ErrorMessage } from "@/constant";
import { VideoModel } from "@/models";
import { Common } from "../Common";

export class VideoForm {
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) title = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) @MaxLength(30, { message: "最多輸入30字" }) link = '';
    @IsDefined() @IsNotEmpty({ message: "必填" }) active = '';
    vid = '';
    token = window.localStorage.getItem('adminToken') as string;
}

@Component<AdminVideosAddController>({
    components: {
        SideNav, Lightbox
    }
})
export default class AdminVideosAddController extends Vue {
    /*定義data 變數 */
    private doubleClick = false;
    private videoForm = new VideoForm();
    private token = window.localStorage.getItem('adminToken') as string;

    private errorMsg = "";
    private openLB = false;
    private errNo = 0;

    private errorMsgMap: { [key: string]: string } = {
        title: '',
        link: '',
        active: '',
    };

    public async created() {
        const item = {
            vid: this.$route.query.vid,
            token: this.token,
        }
        const editData = await VideoModel.get(item);
        this.assignDataToForm(editData);
    }

    private assignDataToForm(data: unknown) {
        const key = data as {
            ERR_CODE: number,
            info: object,
        }
        if (key.ERR_CODE === ErrorCode.InvalidToken) {
            Common.logout();
            return;
        }
        const item = key.info as { [key: string]: string };

        this.videoForm.vid = item.vid;
        this.videoForm.title = item.title;
        this.videoForm.link = item.link;
        this.videoForm.active = item.active;
    }

    private validateAndSubmit() {
        validate(this.videoForm, { skipMissingProperties: true }).then(
            async result => {
                if (result.length !== 0) {
                    this.errorMsgMap = { //先清空error message
                        title: '',
                        link: '',
                        active: '',
                    };
                    result.forEach((item, index) => {
                        const error = item.constraints as {
                            [key: string]: string;
                        };
                        Object.keys(error).forEach(key => {
                            this.errorMsgMap[item.property] = error[key]; //放入error message
                        });
                    });
                    return;
                }

                if (!this.doubleClick) {
                    this.doubleClick = true;

                    const item = JSON.parse(JSON.stringify(this.videoForm));

                    this.errNo = await VideoModel.edit(item);
                    if (this.errNo === ErrorCode.InvalidToken) {
                        Common.logout();
                        return;
                    }
                    this.openLB = true;
                    this.doubleClick = false;
                    this.errorMsg = ErrorMessage[this.errNo];
                }
            }
        )
    }
    private closeLB() {
        this.openLB = false;
        if (this.errNo === ErrorCode.Success) {
            this.$router.push("/admin/video");
        }
    }
}
